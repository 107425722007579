/*
  Dwarf Styles
*/

@font-face {
  font-family: FixelDisplay-Bold;
  src: url(/fonts/FixelDisplay-Bold.ttf),
       url(/fonts/FixelDisplay-Bold.woff2);
}

@font-face {
  font-family: FixelDisplay-SemiBold;
  src: url(/fonts/FixelDisplay-SemiBold.ttf),
       url(/fonts/FixelDisplay-SemiBold.woff2);
}

@font-face {
  font-family: FixelDisplay-Medium;
  src: url(/fonts/FixelDisplay-Medium.ttf),
       url(/fonts/FixelDisplay-Medium.woff2);
}

@font-face {
  font-family: FixelText-Regular;
  src: url(/fonts/FixelText-Regular.ttf),
       url(/fonts/FixelText-Regular.woff2);
}

@font-face {
  font-family: FixelText-SemiBold;
  src: url(/fonts/FixelText-SemiBold.ttf),
       url(/fonts/FixelText-SemiBold.woff2);
}

html {
  background-image: url('/img/bg-desktop.png');
  background-color: #f8f4f09c;

  text-transform: uppercase;
  color: #122930;
  font-family: FixelText-Regular;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

header, div#main, footer, div#action-body {
  padding-top: 1.4vw;
  margin: 0 auto;
  width: 94.375vw;
}

@media screen and (max-width: 1024px) {
  .desktop-only {
    display: none !important;
  }

  header, div#main, footer, div#action-body {
    width: 90vw;
  }

  header {
    padding-top: 8vw !important;
    grid-template-columns: 29vw 61vw !important;
  }

  .logo {
    width: 29vw !important;
  }

  #nav-burger {
    font-size: 6.66vw;
    text-align: center;
    line-height: 2;
    float: right;
  }

  .section-header {
    grid-template-columns: 100% !important;
    row-gap: 4vw !important;
  }

  p.section-description {
    font-size: 3.889vw !important;
    line-height: 5.056vw !important;
  }

  div#intro {
    margin-top: 22vw !important;
  }

  div#intro h1 {
    font-size: 8.333vw !important;
    line-height: 8.333vw !important;
  }

  div#intro p {
    display: none !important;
  }

  div#intro > dotlottie-player {
    margin-top: -18% !important;
    width: 102vw !important;
    margin-left: -8vw !important;
  }

  .card {
    height: 25.833vw !important;
  }

  .card img {
    max-height: 15vw !important;
    max-width: 25vw !important;
  }

  .card-back p {
    font-size: 3vw !important;
    line-height: 3vw !important;
  }

  div#team {
    margin-top: 15vw !important;
  }

  div#team h2 {
    width: 100% !important;
    font-size: 7.778vw !important;
    line-height: 7.778vw !important;
  }

  div#team #cards {
    padding-top: 5vw;
    width: 100% !important;
    grid-template-columns: repeat(2, 1fr) !important;
    column-gap: 4vw !important;
    row-gap: 4vw !important;
  }

  div#services {
    margin-top: 20vw !important;
  }

  div#services-header {
    grid-template-columns: 100%;
  }

  #services > div.section-header > h2 {
    font-size: 7.778vw !important;
    line-height: 7.778vw !important;
  }

  div#services-top p, div#services-bottom p {
    font-size: 5.556vw !important;
    line-height: 5.556vw !important;
  }

  div#services-top {
    grid-template-columns: 1fr !important;
  }

  div#services-bottom {
    grid-template-columns: 1fr !important;
  }

  div#services-top, div#services-bottom {
    row-gap: 4vw !important;
  }

  div#services-top > div, div#services-bottom > div {
    padding: 5vw !important;
  }

  .div-split {
    grid-template-columns: 1fr !important;
    grid-template-rows: 50% !important;
  }

  div#services-top > div > .div-split dotlottie-player {
    margin-top:15vw !important;
    margin-bottom:-33vw !important;
    height: 80vw !important;
  }

  div#services-bottom > div > .div-split dotlottie-player {
    margin-top:10vw !important;
    margin-bottom:-20vw !important;
    height: 80vw !important;
  }

  .div-split p {
    padding: 0px;
  }

  .div-single {
    padding: 25vw 0px 0px 0px;
  }

  div#costs {
    margin-top: 20vw !important;
  }

  div#costs > div > div {
    grid-template-columns: 1fr !important;
  }

  div#costs h2 {
    font-size: 10.556vw !important;
    line-height: 10.556vw !important;
    width: 100% !important;
  }

  div.costs-section {
    grid-template-columns: 18vw 66vw !important;
    padding-top: 8vw !important;
  }

  div.costs-section img {
    width: 14vw !important;
  }

  div.costs-section h3 {
    font-size: 5.556vw !important;
    line-height: 5.556vw !important;
  }

  div.costs-section p {
    font-size: 3.889vw !important;
    line-height: 5.444vw !important;
  }

  div#action-body {
    margin-top: 20vw !important;
  }

  div#action h2 {
    padding: 15vw 0px 0px 0px !important;
    font-size: 7.778vw !important;
    line-height: 7.778vw !important;
    width: 100% !important;
    z-index: 2;
  }

  div#action #cloud2 {
    margin-top: 10vw !important;
    margin-bottom: -5vw !important;
    margin-left: -5vw !important;
    left: 0;
  }

  div#action #cloud2 img {
    height: 14.87vw !important;
  }

  div#action #cloud3 {
    margin-top: 0vw !important;
    margin-right: -17vw !important;
    right: 0;
  }

  div#action #cloud3 img {
    height: 15vw !important;
  }

  div#action-body > div > dotlottie-player.mobile-only {
    margin-top: -30vw !important;
    width: 110vw !important;
    margin-left: -7vw;
  }

  div#footer-body {
    grid-template-columns: 1fr !important;
  }

  div #footer-nav {
    grid-template-columns: 1fr !important;
  }

  footer h3, footer p {
    font-size: 3.889vw !important;
    line-height: 5.056vw !important;
  }

  div#footer-nav > div {
    padding-bottom: 5vw;
  }

  a {
    font-size: 3.889vw !important;
    line-height: 5.056vw !important;
  }
}

@media only screen and (min-width: 1025px) {
  .mobile-only {
    display: none !important;
  }
}

a {
  color: #122930;
  text-decoration: none;
  display: inline-block;
  position: relative;
  font-size: 0.729vw;
  line-height: 0.938vw;
  letter-spacing: -0.01em;
  vertical-align: middle;
}

p {
  color: #122930;
  font-size: 0.729vw;
  line-height: 0.938vw;
}

a:hover {
  color: #E96F3C;
  transition: 0.25s;
}

a.effect::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.2px;
  bottom: 0;
  left: 0;
  background-color: #122930;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

a.effect:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

em {
  color: #E96F3C;
  font-style: normal;
}

header {
  display: grid;
  grid-template-rows: 2.681vw;
  grid-template-columns: 26.5vw 43.5vw 24.375vw;
  column-gap: 0px;
  row-gap: 0px;
  align-items: center;

  letter-spacing: -0.01em;
  text-align: left;
}

.logo {
  width: 7.7vw;
}

.section-description {
  font-size: 0.729vw;
  line-height: 0.948vw;
  color: #122930;
  opacity: 50%;
}

header ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

header > ul > li {
  display: inline-block;
  margin: 0 0.75vw;
}

div#social {
  padding: 0 0px;
}

div#social a {
  margin: 0 1vw;
}

div#social a img {
  height: 1.458vw;
}

div#mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 4.25vw;
  padding-left: 5vw;
  padding-right: 8vw;
  z-index: 5;
  background-image: url('/img/bg-desktop.png');
  background-color: #f8f4f0;
  animation: nav-fade 0.25s;
}

@keyframes nav-fade {
  0% { opacity: 0%; }
  100% { opacity: 100%; }
}

div#mobile-nav > div:nth-child(1) {
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-template-rows: 1fr;
  column-gap: 0px;
  row-gap: 0px;
}

#mobile-nav > div:nth-child(1) > a > i {
  font-size: 2em;
  margin-top: -5em;
}

div#mobile-nav ul {
  padding-top: 30vw;
}

div#mobile-nav li {
  margin: 4vw 0vw;
}

div#mobile-nav a {
  font-size: 6.667vw;
  line-height: 8.667vw;
  margin: 4vw 4vw;
}

div#mobile-nav-social {
  position: fixed;
  bottom: 0;
}

div#mobile-nav-social a {
  padding: 10vw 0px;
}

#mobile-nav-social > a > img {
  width: 7.77vw;
  padding-right: 2vw;
}

div#intro {
  margin-top: 8vw;
}

.section-header {
  display: grid;
  grid-template-columns: 27vw 67.375vw;
  align-items: start;
}

div#intro h1 {
  margin: 0;
  position: relative;
  z-index: 2;
  font-family: FixelDisplay-Bold;
  font-size: 3.333vw;
  line-height: 3.333vw;
  letter-spacing: -0.02em;
  text-align: left;
  width: 45vw;
}

div#intro > p {
  position: relative;
  margin-top: 4.5vw;
  z-index: 2;
  width: 9.89vw;
  font-family: FixelDisplay-Medium;
  font-size: 0.833vw;
  line-height: 0.990vw;
  letter-spacing: 0em;
}

div#intro > dotlottie-player {
  margin-top: -14vw;
}

#team {
  scroll-margin-top: 100px;
}

div#team {
  margin-top: 9.5vw;
}

div#team h2 {
  font-family: FixelText-SemiBold;
  font-size: 2.188vw;
  line-height: 2.188vw;
  width: 43.95vw;
  letter-spacing: -0.02em;
  margin: 0;
  text-transform: none;
}

div#team #cards {
  grid-template-columns: repeat(3, 1fr);
  width: 40.2vw;
  margin: 0 auto;
  margin-top: 3.5vw;
  display: grid;
  column-gap: 1.3vw;
  row-gap: 1.3vw;
}

.card {
  background-color: transparent;
  perspective: 1000px;
  height: 8.54vw;
}

.card img {
  min-width: 2vw;
  max-width: 10vw;
  max-height: 4vw;
  border: 0;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  border-radius: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-front {
  background-color: #F1E6DB;
}

.card-back {
  background-color: #122930;
  transform: rotateY(180deg);
}

.card-back p {
  color: #F1E6DB;
  font-family: FixelDisplay-Medium;
  font-size: 1.042vw;
  line-height: 1.042vw;
}

#services {
  scroll-margin-top: 100px;
}

div#services {
  display: grid;
  column-gap: 0px;
  row-gap: 1.3vw;
  margin-top: 9.5vw;
}

div#services > div.section-header > h2 {
  font-family: FixelDisplay-Bold;
  font-size: 3.021vw;
  line-height: 3.021vw;
  letter-spacing: -0.02em;
  text-align: left;
  margin-top: 0;
  margin-bottom: 10vw;
}

div#services p {
  margin: 0;
  letter-spacing: -0.02em;
}

div#services-top {
  grid-template-columns: 67.5vw 26.875vw;
}

div#services-bottom {
  grid-template-columns: 26.875vw 67.5vw;
}

div#services-top, div#services-bottom {
  display: grid;
  grid-template-rows: 1fr;
  column-gap: 1.3vw;
}

div#services-top > div, div#services-bottom > div {
  padding: 2vw;
  border-radius: 2.604vw;
  display: flex;
}

div#services-top p, div#services-bottom p {
  font-family: FixelDisplay-SemiBold;
  font-size: 1.771vw;
  line-height: 1.771vw;
}

div#services-top > div > p, div#services-bottom > div > p {
  float: left;
  position: absolute;
}

div#services-top > div:nth-child(1), div#services-bottom > div:nth-child(2), div#services-top > div:nth-child(1), div#services-bottom > div:nth-child(3) {
  background-color: #F1E6DB;
}

div#services-top > div:nth-child(2), div#services-bottom > div:nth-child(1), div#services-top > div:nth-child(2), div#services-bottom > div:nth-child(4) {
  background-color: #122930;
  display: flex;
}

div#services-top > div:nth-child(2), div#services-bottom > div:nth-child(1) p, div#services-top > div:nth-child(2) p, div#services-bottom > div:nth-child(4) p {
  color: #FFF;
}

div#services-top > div:nth-child(2) > p:nth-child(2), div#services-bottom > div:nth-child(1) > p:nth-child(2) {
  float: none;
  display: inline-block;
  align-self: flex-end;
}

.div-split {
  display: grid;
  grid-template-columns: 3fr 4fr;
  justify-items: baseline;
  align-items: flex-end;
}

.div-single {
  display: grid;
  justify-items: baseline;
  align-items: flex-end;
}

div#services-top > div > .div-split dotlottie-player {
  margin-top:-5vw;
  margin-bottom:-3vw;
  height: 29.795vw;
}

div#services-bottom > div > .div-split dotlottie-player {
  margin-top:-7vw;
  margin-bottom:-1vw;
  height: 29.795vw;
}

#costs {
  scroll-margin-top: 100px;
}

div#costs {
  display: grid;
  margin-top: 9.5vw;
}

div#costs > div > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

div#costs h2 {
  font-family: FixelDisplay-Bold;
  font-size: 4.479vw;
  line-height: 4.479vw;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 0;
  width: 5vw;
}

div#costs-sections > div:nth-child(2) {
  align-self: baseline;
}

div.costs-section {
  display: grid;
  grid-template-columns: 4.167vw 15vw;
  column-gap: 1vw;
  padding-bottom: 3vw;
}

div.costs-section img {
  width: 4.167vw;
}

div.costs-section h3 {
  font-family: FixelDisplay-Bold;
  line-height: 1.563vw;
  font-size: 1.563vw;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 0;
}

div.costs-section p {
  text-transform: none;
  font-size: 0.833vw;
  line-height: 1.167vw;
}

div#action {
  background-color: #F1E6DB;
  z-index: -2;
}

div#action #cloud1 {
  z-index: 1;
  margin-top: -7.5vw;
  margin-left: 1vw;
  left: 0;
}

div#action #cloud1 img {
  width: 15.313vw;
}

div#action #cloud2 {
  float: left;
  z-index: 2;
  margin-top: 3vw;
  margin-left: -3.625vw;
  left: 0;
}

div#action #cloud2 img {
  height: 7.506vw;
}

div#action #cloud3 {
  float: right;
  z-index: 2;
  right: 0;
  margin-top: -6vw;
  margin-right: -3.625vw;
}

div#action #cloud3 img {
  height: 7.052vw;
}

div#action h2 {
  position: relative;
  z-index: 5;
  font-family: FixelDisplay-Bold;
  letter-spacing: -0.02em;
  text-align: center;
  padding-top: 6vw;
  font-size: 3.021vw;
  line-height: 3.323vw;
  width: 45.5vw;
  margin: 0 auto;
}

div#action-body {
  margin-top: 9.5vw;
}

div#action-body > div > dotlottie-player {
  width: 83.229vw;
  margin: 0 auto;
  padding-bottom: 2vw;
}

footer {
  margin-top: 4.5vw;
  font-size: 0.729vw;
  line-height: 0.938vw;
}

footer > h3 {
  color: #F0EBE5;
  font-family: FixelDisplay-Bold;
  font-size: 10.525vw;
  line-height: 10.525vw;
  letter-spacing: -0.02em;
  margin-top: 3.5vw;
  margin-bottom: 0;
  padding: 0;
}

footer > p {
  margin-bottom: 3.5vw;
  text-transform: none;
}

div#footer-body {
  display: grid;
  grid-template-columns: 34.5vw 30vw 37.875vw;
  grid-template-rows: 1fr;
  column-gap: 0px;
  row-gap: 0px;
}

div#footer-body > img {
  width: 7.708vw;
}

div#footer-nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  column-gap: 0px;
  row-gap: 0px;
}

div#footer-nav h3 {
  opacity: 50%;
}
