@font-face {
  font-family: FixelDisplay-Bold;
  src: url("FixelDisplay-Bold.a5f52522.ttf"), url("FixelDisplay-Bold.9eaabbe3.woff2");
}

@font-face {
  font-family: FixelDisplay-SemiBold;
  src: url("FixelDisplay-SemiBold.52fec401.ttf"), url("FixelDisplay-SemiBold.7865af01.woff2");
}

@font-face {
  font-family: FixelDisplay-Medium;
  src: url("FixelDisplay-Medium.d6ee803a.ttf"), url("FixelDisplay-Medium.5480a33c.woff2");
}

@font-face {
  font-family: FixelText-Regular;
  src: url("FixelText-Regular.093d02bb.ttf"), url("FixelText-Regular.d81fda6e.woff2");
}

@font-face {
  font-family: FixelText-SemiBold;
  src: url("FixelText-SemiBold.955788dd.ttf"), url("FixelText-SemiBold.ccc3bc78.woff2");
}

html {
  text-transform: uppercase;
  color: #122930;
  scroll-behavior: smooth;
  background-color: #f8f4f09c;
  background-image: url("bg-desktop.a18942e6.png");
  font-family: FixelText-Regular;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

header, div#main, footer, div#action-body {
  width: 94.375vw;
  margin: 0 auto;
  padding-top: 1.4vw;
}

@media screen and (max-width: 1024px) {
  .desktop-only {
    display: none !important;
  }

  header, div#main, footer, div#action-body {
    width: 90vw;
  }

  header {
    grid-template-columns: 29vw 61vw !important;
    padding-top: 8vw !important;
  }

  .logo {
    width: 29vw !important;
  }

  #nav-burger {
    text-align: center;
    float: right;
    font-size: 6.66vw;
    line-height: 2;
  }

  .section-header {
    grid-template-columns: 100% !important;
    row-gap: 4vw !important;
  }

  p.section-description {
    font-size: 3.889vw !important;
    line-height: 5.056vw !important;
  }

  div#intro {
    margin-top: 22vw !important;
  }

  div#intro h1 {
    font-size: 8.333vw !important;
    line-height: 8.333vw !important;
  }

  div#intro p {
    display: none !important;
  }

  div#intro > dotlottie-player {
    width: 102vw !important;
    margin-top: -18% !important;
    margin-left: -8vw !important;
  }

  .card {
    height: 25.833vw !important;
  }

  .card img {
    max-width: 25vw !important;
    max-height: 15vw !important;
  }

  .card-back p {
    font-size: 3vw !important;
    line-height: 3vw !important;
  }

  div#team {
    margin-top: 15vw !important;
  }

  div#team h2 {
    width: 100% !important;
    font-size: 7.778vw !important;
    line-height: 7.778vw !important;
  }

  div#team #cards {
    padding-top: 5vw;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 4vw !important;
    width: 100% !important;
  }

  div#services {
    margin-top: 20vw !important;
  }

  div#services-header {
    grid-template-columns: 100%;
  }

  #services > div.section-header > h2 {
    font-size: 7.778vw !important;
    line-height: 7.778vw !important;
  }

  div#services-top p, div#services-bottom p {
    font-size: 5.556vw !important;
    line-height: 5.556vw !important;
  }

  div#services-top, div#services-bottom {
    grid-template-columns: 1fr !important;
    row-gap: 4vw !important;
  }

  div#services-top > div, div#services-bottom > div {
    padding: 5vw !important;
  }

  .div-split {
    grid-template-rows: 50% !important;
    grid-template-columns: 1fr !important;
  }

  div#services-top > div > .div-split dotlottie-player {
    height: 80vw !important;
    margin-top: 15vw !important;
    margin-bottom: -33vw !important;
  }

  div#services-bottom > div > .div-split dotlottie-player {
    height: 80vw !important;
    margin-top: 10vw !important;
    margin-bottom: -20vw !important;
  }

  .div-split p {
    padding: 0;
  }

  .div-single {
    padding: 25vw 0 0;
  }

  div#costs {
    margin-top: 20vw !important;
  }

  div#costs > div > div {
    grid-template-columns: 1fr !important;
  }

  div#costs h2 {
    width: 100% !important;
    font-size: 10.556vw !important;
    line-height: 10.556vw !important;
  }

  div.costs-section {
    grid-template-columns: 18vw 66vw !important;
    padding-top: 8vw !important;
  }

  div.costs-section img {
    width: 14vw !important;
  }

  div.costs-section h3 {
    font-size: 5.556vw !important;
    line-height: 5.556vw !important;
  }

  div.costs-section p {
    font-size: 3.889vw !important;
    line-height: 5.444vw !important;
  }

  div#action-body {
    margin-top: 20vw !important;
  }

  div#action h2 {
    z-index: 2;
    width: 100% !important;
    padding: 15vw 0 0 !important;
    font-size: 7.778vw !important;
    line-height: 7.778vw !important;
  }

  div#action #cloud2 {
    left: 0;
    margin-top: 10vw !important;
    margin-bottom: -5vw !important;
    margin-left: -5vw !important;
  }

  div#action #cloud2 img {
    height: 14.87vw !important;
  }

  div#action #cloud3 {
    right: 0;
    margin-top: 0 !important;
    margin-right: -17vw !important;
  }

  div#action #cloud3 img {
    height: 15vw !important;
  }

  div#action-body > div > dotlottie-player.mobile-only {
    margin-left: -7vw;
    width: 110vw !important;
    margin-top: -30vw !important;
  }

  div#footer-body, div #footer-nav {
    grid-template-columns: 1fr !important;
  }

  footer h3, footer p {
    font-size: 3.889vw !important;
    line-height: 5.056vw !important;
  }

  div#footer-nav > div {
    padding-bottom: 5vw;
  }

  a {
    font-size: 3.889vw !important;
    line-height: 5.056vw !important;
  }
}

@media only screen and (min-width: 1025px) {
  .mobile-only {
    display: none !important;
  }
}

a {
  color: #122930;
  letter-spacing: -.01em;
  vertical-align: middle;
  font-size: .729vw;
  line-height: .938vw;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

p {
  color: #122930;
  font-size: .729vw;
  line-height: .938vw;
}

a:hover {
  color: #e96f3c;
  transition: all .25s;
}

a.effect:after {
  content: "";
  transform-origin: 100% 100%;
  background-color: #122930;
  width: 100%;
  height: 1.2px;
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

a.effect:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

em {
  color: #e96f3c;
  font-style: normal;
}

header {
  letter-spacing: -.01em;
  text-align: left;
  grid-template-rows: 2.681vw;
  grid-template-columns: 26.5vw 43.5vw 24.375vw;
  align-items: center;
  gap: 0;
  display: grid;
}

.logo {
  width: 7.7vw;
}

.section-description {
  color: #122930;
  opacity: .5;
  font-size: .729vw;
  line-height: .948vw;
}

header ul {
  padding-inline-start: 0;
  list-style-type: none;
}

header > ul > li {
  margin: 0 .75vw;
  display: inline-block;
}

div#social {
  padding: 0;
}

div#social a {
  margin: 0 1vw;
}

div#social a img {
  height: 1.458vw;
}

div#mobile-nav {
  z-index: 5;
  background-color: #f8f4f0;
  background-image: url("bg-desktop.a18942e6.png");
  padding-top: 4.25vw;
  padding-left: 5vw;
  padding-right: 8vw;
  animation: .25s nav-fade;
  display: none;
  position: fixed;
  inset: 0;
}

@keyframes nav-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

div#mobile-nav > div:first-child {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 30px;
  gap: 0;
  display: grid;
}

#mobile-nav > div:first-child > a > i {
  margin-top: -5em;
  font-size: 2em;
}

div#mobile-nav ul {
  padding-top: 30vw;
}

div#mobile-nav li {
  margin: 4vw 0;
}

div#mobile-nav a {
  margin: 4vw;
  font-size: 6.667vw;
  line-height: 8.667vw;
}

div#mobile-nav-social {
  position: fixed;
  bottom: 0;
}

div#mobile-nav-social a {
  padding: 10vw 0;
}

#mobile-nav-social > a > img {
  width: 7.77vw;
  padding-right: 2vw;
}

div#intro {
  margin-top: 8vw;
}

.section-header {
  grid-template-columns: 27vw 67.375vw;
  align-items: start;
  display: grid;
}

div#intro h1 {
  z-index: 2;
  letter-spacing: -.02em;
  text-align: left;
  width: 45vw;
  margin: 0;
  font-family: FixelDisplay-Bold;
  font-size: 3.333vw;
  line-height: 3.333vw;
  position: relative;
}

div#intro > p {
  z-index: 2;
  letter-spacing: 0;
  width: 9.89vw;
  margin-top: 4.5vw;
  font-family: FixelDisplay-Medium;
  font-size: .833vw;
  line-height: .99vw;
  position: relative;
}

div#intro > dotlottie-player {
  margin-top: -14vw;
}

#team {
  scroll-margin-top: 100px;
}

div#team {
  margin-top: 9.5vw;
}

div#team h2 {
  letter-spacing: -.02em;
  text-transform: none;
  width: 43.95vw;
  margin: 0;
  font-family: FixelText-SemiBold;
  font-size: 2.188vw;
  line-height: 2.188vw;
}

div#team #cards {
  grid-template-columns: repeat(3, 1fr);
  gap: 1.3vw;
  width: 40.2vw;
  margin: 3.5vw auto 0;
  display: grid;
}

.card {
  perspective: 1000px;
  background-color: #0000;
  height: 8.54vw;
}

.card img {
  border: 0;
  min-width: 2vw;
  max-width: 10vw;
  max-height: 4vw;
}

.card-inner {
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  transition: transform .8s;
  position: relative;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  backface-visibility: hidden;
  text-align: center;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.card-front {
  background-color: #f1e6db;
}

.card-back {
  background-color: #122930;
  transform: rotateY(180deg);
}

.card-back p {
  color: #f1e6db;
  font-family: FixelDisplay-Medium;
  font-size: 1.042vw;
  line-height: 1.042vw;
}

#services {
  scroll-margin-top: 100px;
}

div#services {
  gap: 1.3vw 0;
  margin-top: 9.5vw;
  display: grid;
}

div#services > div.section-header > h2 {
  letter-spacing: -.02em;
  text-align: left;
  margin-top: 0;
  margin-bottom: 10vw;
  font-family: FixelDisplay-Bold;
  font-size: 3.021vw;
  line-height: 3.021vw;
}

div#services p {
  letter-spacing: -.02em;
  margin: 0;
}

div#services-top {
  grid-template-columns: 67.5vw 26.875vw;
}

div#services-bottom {
  grid-template-columns: 26.875vw 67.5vw;
}

div#services-top, div#services-bottom {
  grid-template-rows: 1fr;
  column-gap: 1.3vw;
  display: grid;
}

div#services-top > div, div#services-bottom > div {
  border-radius: 2.604vw;
  padding: 2vw;
  display: flex;
}

div#services-top p, div#services-bottom p {
  font-family: FixelDisplay-SemiBold;
  font-size: 1.771vw;
  line-height: 1.771vw;
}

div#services-top > div > p, div#services-bottom > div > p {
  float: left;
  position: absolute;
}

div#services-top > div:first-child, div#services-bottom > div:nth-child(2), div#services-top > div:first-child, div#services-bottom > div:nth-child(3) {
  background-color: #f1e6db;
}

div#services-top > div:nth-child(2), div#services-bottom > div:first-child, div#services-top > div:nth-child(2), div#services-bottom > div:nth-child(4) {
  background-color: #122930;
  display: flex;
}

div#services-top > div:nth-child(2), div#services-bottom > div:first-child p, div#services-top > div:nth-child(2) p, div#services-bottom > div:nth-child(4) p {
  color: #fff;
}

div#services-top > div:nth-child(2) > p:nth-child(2), div#services-bottom > div:first-child > p:nth-child(2) {
  float: none;
  align-self: flex-end;
  display: inline-block;
}

.div-split {
  grid-template-columns: 3fr 4fr;
  place-items: flex-end baseline;
  display: grid;
}

.div-single {
  place-items: flex-end baseline;
  display: grid;
}

div#services-top > div > .div-split dotlottie-player {
  height: 29.795vw;
  margin-top: -5vw;
  margin-bottom: -3vw;
}

div#services-bottom > div > .div-split dotlottie-player {
  height: 29.795vw;
  margin-top: -7vw;
  margin-bottom: -1vw;
}

#costs {
  scroll-margin-top: 100px;
}

div#costs {
  margin-top: 9.5vw;
  display: grid;
}

div#costs > div > div {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

div#costs h2 {
  letter-spacing: -.02em;
  text-align: left;
  width: 5vw;
  margin: 0;
  font-family: FixelDisplay-Bold;
  font-size: 4.479vw;
  line-height: 4.479vw;
}

div#costs-sections > div:nth-child(2) {
  align-self: baseline;
}

div.costs-section {
  grid-template-columns: 4.167vw 15vw;
  column-gap: 1vw;
  padding-bottom: 3vw;
  display: grid;
}

div.costs-section img {
  width: 4.167vw;
}

div.costs-section h3 {
  letter-spacing: -.02em;
  text-align: left;
  margin: 0;
  font-family: FixelDisplay-Bold;
  font-size: 1.563vw;
  line-height: 1.563vw;
}

div.costs-section p {
  text-transform: none;
  font-size: .833vw;
  line-height: 1.167vw;
}

div#action {
  z-index: -2;
  background-color: #f1e6db;
}

div#action #cloud1 {
  z-index: 1;
  margin-top: -7.5vw;
  margin-left: 1vw;
  left: 0;
}

div#action #cloud1 img {
  width: 15.313vw;
}

div#action #cloud2 {
  float: left;
  z-index: 2;
  margin-top: 3vw;
  margin-left: -3.625vw;
  left: 0;
}

div#action #cloud2 img {
  height: 7.506vw;
}

div#action #cloud3 {
  float: right;
  z-index: 2;
  margin-top: -6vw;
  margin-right: -3.625vw;
  right: 0;
}

div#action #cloud3 img {
  height: 7.052vw;
}

div#action h2 {
  z-index: 5;
  letter-spacing: -.02em;
  text-align: center;
  width: 45.5vw;
  margin: 0 auto;
  padding-top: 6vw;
  font-family: FixelDisplay-Bold;
  font-size: 3.021vw;
  line-height: 3.323vw;
  position: relative;
}

div#action-body {
  margin-top: 9.5vw;
}

div#action-body > div > dotlottie-player {
  width: 83.229vw;
  margin: 0 auto;
  padding-bottom: 2vw;
}

footer {
  margin-top: 4.5vw;
  font-size: .729vw;
  line-height: .938vw;
}

footer > h3 {
  color: #f0ebe5;
  letter-spacing: -.02em;
  margin-top: 3.5vw;
  margin-bottom: 0;
  padding: 0;
  font-family: FixelDisplay-Bold;
  font-size: 10.525vw;
  line-height: 10.525vw;
}

footer > p {
  text-transform: none;
  margin-bottom: 3.5vw;
}

div#footer-body {
  grid-template-rows: 1fr;
  grid-template-columns: 34.5vw 30vw 37.875vw;
  gap: 0;
  display: grid;
}

div#footer-body > img {
  width: 7.708vw;
}

div#footer-nav {
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  display: grid;
}

div#footer-nav h3 {
  opacity: .5;
}

/*# sourceMappingURL=index.fc03c2b7.css.map */
